/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"

import Footer from "./footer"
import Header from "./header"
import "./layout.sass"

const Layout = ({ hero, children }) => {

  typeof window !== "undefined" && window.gtag && window.gtag('consent', 'default', {
    'analytics_storage': 'denied'
  });
  
  if (hero) {
  	return (
  		<>
        <Helmet>
          <link href="https://fonts.googleapis.com/css?family=Barlow:300,400,600,700,800&display=swap" rel="stylesheet" />
          <link href="https://fonts.googleapis.com/css?family=Karla:400,600,700,800&display=swap" rel="stylesheet" />
        </Helmet>
  			<main>{children}</main>
  		</>
  	)
  } else {
	  return (
	    <>
        <Helmet>
          <link href="https://fonts.googleapis.com/css?family=Barlow:300,400,600,700,800&display=swap" rel="stylesheet" />
          <link href="https://fonts.googleapis.com/css?family=Karla:400,600,700,800&display=swap" rel="stylesheet" />
        </Helmet>
	    	<Header />
	      	<main>{children}</main>
	    	<Footer />
	    </>
	  )
	}
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  hero: PropTypes.bool
}

export default Layout
