import React from "react"

import logoWithText from "../images/logo-white-with-text.svg"
import LaunchButton from "./launch-button"
import Link from "./link"
//import languageIcon from "../images/language-icon.svg"


const Header = function() {
  return (
    <div id='headerContainer'>
      <header>
        <Link className="logoLink" to="/">
          <img className="headerLogo" src={logoWithText} alt="Tideal" />
        </Link>
        <aside>
          <LaunchButton />
        </aside>
      </header>
    </div>
  );
};

/*
class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentLanguage: "En"
    }
    this.changeLanguage.bind(this);
  }

  changeLanguage = function(that) {
    console.log(that.state.currentLanguage);
    this.setState({currentLanguage : (this.state.currentLanguage === "En") ? "Fi" : "En"});
  }

  render() {
    return (<div id='headerContainer'>
      <header>
        <Link className="logoLink" to="/">
          <img className="headerLogo" src={logoWithText} alt="Tideal" />
        </Link>
        <aside>
          {/*
            <button className="languageButton" onClick={ ()=> { this.changeLanguage(this) }}>
              <img src={languageIcon} alt="Language" />
              <span>{this.state.currentLanguage}</span>
            </button>
          *//*}
          <LaunchButton />
        </aside>
       </header>
    </div>
    )
  }
}
*/

export default Header;
