import React from 'react'

import Link from "./link"
import facebookIcon from "../images/some-facebook.svg"
import instagramIcon from "../images/some-instagram.svg"
import twitterIcon from "../images/some-twitter.svg"

const SocialLinks = ({url}) => (
   <div className={url ? "socialLinks share" : "socialLinks" } >
      <Link className="socialButton facebook" href={ "https://www.facebook.com/" + (url ? ("sharer.php?u=" + url) : "tidealapp") }>
        <img src={facebookIcon} alt={ (url ? "Share on " : "Tideal @ ") + "Facebook" } />
      </Link>  
      { url ? "" : <Link className="socialButton instagram" href="http://www.instagram.com/tideal_app">
        <img src={instagramIcon} alt="Tideal @ Instagram" />
      </Link> }  
      <Link className="socialButton twitter" href={ "http://www.twitter.com/" + (url ? ("intent/tweet?url=" + url) : "tideal_app") }>
        <img src={twitterIcon} alt={ (url ? "Share on " : "Tideal @ ") + "Twitter" } />
      </Link>
    </div>
)


export default SocialLinks