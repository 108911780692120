import React from "react"
import { Link as GatsbyLink } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-gtag"

const Link = ({ children, to, activeClassName, partiallyActive, ...other }) => {

  const internal = /(^http:\/\/tideal\.app|^\/(?!\/))/.test(to)

  // Use Gatsby Link for internal links, and <a> for others
  if (internal) {
    return (
      <GatsbyLink
        to={to}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        {...other}
      >
        {children}
      </GatsbyLink>
    )
  }
  return (
    <OutboundLink href={to} {...other}>
      {children}
    </OutboundLink>
  )
}

export default Link