import React from 'react'

import Link from "./link"
import logoIcon from "../images/logo-white.svg"

const LaunchButton = () => (
		<Link href="https://go.tideal.app">
			<button className="launchButton">
				Launch <span className="hideMobile">Tideal</span> <img src={logoIcon} alt="Tideal-logo" />
		    </button>
		</Link>
)

export default LaunchButton