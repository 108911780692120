import React from "react"

import Link from "./link"
import LogoWithText from "../images/logo-white-with-text.svg"
import LaunchButton from "./launch-button"
import SocialLinks from "./social-links"

const Footer = () => (
  <div id='footerContainer'>
    <footer>
      <Link className="footerLogo" to="/">
        <img className="headerLogo" src={LogoWithText} alt="Tideal" />
      </Link>
        <div className="navLinks">
          <Link className="navLink" to="/">
            <nav>Home</nav>
          </Link>
          <Link className="navLink" to="/about/">
            <nav>About</nav>
          </Link>
          <Link className="navLink" to="/blog/">
            <nav>Articles</nav>
          </Link>
        </div>
      <div className="lastRow">
        <LaunchButton />
        <SocialLinks />
      </div>
    </footer>
  </div>
)

export default Footer
